.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Ensure the overlay is on top of other elements */
  }
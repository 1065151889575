@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);


.footer-distributed {
    background: transparent;
    border-top: 1px solid var(--bg-primary);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    height: '30vh';
    text-align: left;
    font: bold 16px sans-serif;
    padding: 2rem;
    background-color: var(--bg-primary);
    color: white;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */
.footer-distributed h3 {
    color: 'white';
    font-style: 'bold'

}

.footer-distributed .footer-left {
    width: 40%;
}

/* The company logo */
#logoImg {
    height: '1rem !important';
    width: '1rem !important';
}

.footer-distributed h3 {
    color: var(--bg-primary);
    font: normal 36px 'Open Sans', cursive;
    margin: 0;
}

.footer-distributed h3 span {
    color: white
}

/* Footer links */

.footer-distributed .footer-links {
    color: white;
    margin: 10px 0;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    font-weight: 400;
    text-decoration: none;
    color: white;
}

.footer-distributed .footer-company-name {
    color: white;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 30%;
}

.footer-distributed .footer-center i {

    color: white;
    font-size: 25px;
    width: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    margin: 10px;
    vertical-align: middle;
}



.footer-distributed .footer-center p {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 35px;
}

.footer-distributed .footer-center p a {
    color: white;
    text-decoration: none;
    ;
}

.footer-distributed .footer-links a:before {
    content: " |";
    font-weight: 300;
    font-size: 20px;
    left: 0;
    color: white;
    display: inline-block;
    padding-right: 5px;
}

.footer-distributed .footer-links a::after {
    content: ' ';
}

.footer-distributed .footer-links .link-1:before {
    content: none;
}

.footer-distributed .footer-links .link-1::after {
    content: ' ';
}


/* Footer Right */

.footer-distributed .footer-right {
    width: 40%;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: white;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: white;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.footer-distributed .footer-icons {
    margin-top: 15px;
    color: white !important;
}

.footer-distributed .footer-icons a {
    display: inline;
    /* width: 50px;
	height: 35px; */
    cursor: pointer;
    border-radius: 2px;
    font-size: 20px;
    color: white;
    text-align: center;
    line-height: 25px;
    background-color: 'red';
    margin-right: 10px;
    margin-bottom: 10px;
}


/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 1024px) {

    .theme_logo h1 {
        font-size: 50px !important;
    }

    .theme_logo h1 span {
        font-size: 27px !important;
    }

    .footer_custom .padding-left {
        padding-left: 0 !important;
    }

    /* .footer_custom .contact .contact_no .number_block svg {
        margin-right: 7px !important;
    } */

    .column.col1 {
        min-width: 107% !important;
    }

    .footer-distributed {
        font: bold 14px sans-serif;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    .footer-distributed .footer-center i {
        margin-left: 0;
    }

}


/* New Footer Code*/
.theme_logo h1 {
    display: flex;
    flex-direction: column;
    font-family: "Chonburi Serif";
    text-transform: uppercase;
    font-weight: 600;
    font-size: 64px;
    color: #fff;
    text-align: center;
}

.theme_logo h1 span {
    font-size: 31px;
}

.theme_logo {
    display: flex;
    margin-bottom: 20px;
}

.footer_custom {
    background: #0C043F;
    padding-top: 80px;
    padding-bottom: 40px;
    position: relative;
}

.footer_custom .column {
    text-align: left;
}

/* .footer_custom .padding-left {
    padding-left: 100px;
} */

.footer_custom .col1 img {
    width: 210px;
    margin-bottom: 10px;
}

.footer_custom .col1 p {
    color: #fff;
    font-size: 16px;
}

.footer_custom .links h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 26px;
}

.footer_custom .col1 .contact_list {
    margin: 0;
    padding: 0;
}

.footer_custom .col1 .contact_list li {
    list-style: none;
    color: #fff;
    line-height: 32px;
    font-size: 16px;
}

.footer_custom .col1 .contact_list li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    word-break: break-all;
}

.footer_custom .links ul {
    padding: 0;
    margin: 0;
}

.footer_custom .links ul li {
    list-style: none;
    line-height: 35px;
}

.footer_custom .links ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: .3s;
}


.footer_custom .links ul li a:hover {
    padding-left: 8px;
    transition: .3s;
}

.footer_custom .contact ul {
    margin: 0;
    padding: 0;
}

.footer_custom .contact ul li {
    list-style: none;
    display: inline;
    margin-right: 20px;
}

.footer_custom .contact .contact_no {
    text-decoration: none;
}

.footer_custom .contact .contact_no .number_block {
    display: flex;
    align-items: center;
    /* margin-bottom: 20px; */
    border: 1px solid #fff;
    /* width: 140px; */
    border-radius: 8px;
    justify-content: space-around;
    padding: 11px 5px;
    /* gap: 20px; */
}

.footer_custom .contact .contact_no .number_block svg {
    width: 38px;
    height: 38px;
    /* margin-right: 14px; */
    fill: #fff;
}

.footer_custom .contact .contact_no .number_block span {
    color: #fff;
    font-size: 13px;
}

.footer_custom .contact .contact_no .number_block .number_info {
    text-align: left;
}

.footer_custom .col1 .contact_list li a svg {
    width: 15px;
    height: 15px;
    margin-right: 6px;
}

.footer_custom .col1 .contact_list li svg {
    width: 15px;
    height: 15px;
    margin-right: 6px;
}

.footer__bottom_bar {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #413d5d;
    margin-top: 35px;
    padding-top: 30px;
}

.footer__bottom_bar ul {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
}

.footer__bottom_bar ul li {
    list-style: none;
    display: inline-block;
    margin: 0 14px;
}

.footer__bottom_bar ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 12px;
}

.footer__bottom_bar h3 {
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
}

.footer_custom .contact .contact_no .number_block h4 {
    color: #fff;
    font-size: 21px;
    text-align: center;
    font-weight: 700;
}

.footer_custom .contact ul li a {
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}

.footer_custom .contact ul li a svg {
    width: 19px;
    height: 19px;
    fill: #fff;
}

.number-block-svg {
    border-radius: 6px;
    padding: 16px;
    background: #1E1F4B;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .number-block-svg {
        padding: 8px;
    }
}

@media (max-width: 768px) {
    .number-block-svg {
        padding: 5px;
    }

    .footer_custom .contact .contact_no .number_block svg {
        width: 30px;
        height: 30px;
    }

    .footer_custom .col1 img {
        width: 150px;
    }
}

@media (max-width: 425px) {
    .footer_custom .contact .contact_no .number_block {
        width: 170px;
    }
}
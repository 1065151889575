.provider-section {
    padding: 10px 50px;
}

.provider-content {
    text-align: left;
    position: relative;
    /* margin-left: 30px; */
    line-height: 4rem;
}

.provider-content ul {
    list-style-type: none;
    padding-left: 0;
}

.provider-content ul li {
    color: #2E3E5C;
    font-size: 16px;
    font-family: Montserrat;
}

.provider-content h1 {
    /* font-size: 2.5em; */
    margin-bottom: 20px;
}

.provider-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.provider-image {
    text-align: left;
    padding: 0px 35px;
}

.provider-image img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .provider-content h1 {
        font-size: 2em;
    }

    .provider-content p {
        font-size: 1em;
    }

    .provider-section {
        padding: 30px;
    }

    .provider-section .ant-col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 2560px) {
    .provider-section {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}
.two-column-section {
    padding: 50px;
}

.left-column {
    text-align: left;
    padding: 20px;
    border-radius: 8px;
    margin-left: 30px;
}

.left-column h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.left-column p {
    margin-bottom: 20px;
}

.right-column {
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.right-column-absolutebox {
    position: absolute;
    top: 26%;
    left: 8%;
    /* top: 10%;
    left: 10%;
    width: 80%;
    height: 80%; */
}

.right-column img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .right-column-absolutebox img {
        height: 140px;
    }

    .right-column-absolutebox {
        top: 17%;
        left: 7%;
    }
}

@media (max-width: 768px) {
    .two-column-section {
        padding: 30px 10px 0px 10px;
    }

    .left-column {
        margin-bottom: 20px;
    }

    .right-column-absolutebox img {
        height: 95px;
    }

    .right-column-absolutebox {
        top: 15%;
        left: 9%;
    }
}

@media (max-width: 430px) {
    .right-column-absolutebox img {
        height: 110px;
    }

    .right-column-absolutebox {
        top: 23%;
        left: 4%;
    }
}

@media (max-width: 425px) {
    .right-column-absolutebox {
        top: 25%;
        left: 8%;
    }
}

@media (max-width: 375px) {
    .right-column-absolutebox img {
        height: 95px;
    }

    .right-column-absolutebox {
        top: 25%;
        left: 9%;
    }
}


@media (max-width: 320px) {
    .right-column-absolutebox img {
        height: 80px;
    }

    .right-column-absolutebox {
        top: 25%;
        left: 9%;
    }
}

@media (max-width: 2560px) {
    .two-column-section {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}
.testimonialSection {
    position: relative;
}
.testiminialBlcok img {
    width: 100%;
    height: 606px;
    object-fit: cover;
    margin: 0 !important;
}

.testiminialBlcok {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    height: 350px;
}

.opacityBlockTestiminail {
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(11deg, rgb(77 61 178) 0%, rgb(112 111 229 / 0%) 100%);
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
    padding: 30px;
    transition: .3s;
    justify-content: flex-end;
}


.opacityBlockTestiminail:hover {
    transition: .3s;
    background: linear-gradient(11deg, rgba(30, 14, 129, 1) 0%, rgb(112 111 229 / 55%) 100%);
}

.opacityBlockTestiminail p {
    opacity: 0;
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    transition: .3s;
    transform: translateX(90px);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.opacityBlockTestiminail h4 {
    opacity: 0;
    font-family: Poppins;
    transform: translateX(80px);
    transition: .3s;
    color: #fff;
    font-weight: 700;
}

.opacityBlockTestiminail:hover p {
    opacity: 1;
    transform: translateX(0px);
    transition: .3s;
}
.opacityBlockTestiminail:hover h4 {
    transform: translateX(0px);
    opacity: 1;
    transition: .3s;
}

.btnArrowtestiminial {
    position: absolute;
    top: 160px;
    z-index: 100;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 8px;
    background: transparent;
    transition: .3s;
}
.btnArrowtestiminial:hover {
     background: #706fe5;
    transition: .3s;
}
.nextBtn {
    right: 23px;
}

.backBtn {
    left: 23px;
}

.btnArrowtestiminial svg {
    width: 35px;
    height: 32px;
    color: #fff;
}

.showContent p,
.showContent h4 {
    opacity: 1;
    transform: translateX(0px);
}

.showContent .opacityBlockTestiminail {
    background: linear-gradient(11deg, rgba(30, 14, 129, 1) 0%, rgb(112 111 229 / 55%) 100%);
}


@media only screen and (max-width: 1366px) {
    .testiminialBlcok {
        height: 470px !important;
    }
}

@media only screen and (max-width: 550px) {
    .testiminialBlcok img{
        height: 295px;
    }
    .btnArrowtestiminial {
        top: 145px;
    }
 
    .opacityBlockTestiminail p {
        font-size: 13px;
        padding: 0 20px;
        line-height: 17px;
    }
    
    .opacityBlockTestiminail h4 {
        font-size: 16px;
        padding-left: 16px;
    }
    .testiminialBlcok {
        height: 300px !important;
    }
  }
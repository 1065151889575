@import url('https://fonts.googleapis.com/css2?family=Chonburi&family=Montserrat:wght@500&family=Playfair:opsz,wght@5..1200,300&display=swap');

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px
}

.logo {
  color: white;
  font-weight: bold;
}

.menu-container {
  display: flex;
  align-items: center;
  gap: 20px
}

.buttons {
  display: flex;
}

.button {
  margin-right: 10px;
}

.mobile-menu-button {
  display: none;
}

.menu .ant-menu-light>.ant-menu .ant-menu-item {
  height: fit-content;
}

.transparent-bg .ant-menu-submenu-inner {
  background-color: transparent !important;
  /* Override Ant Design default background */
  box-shadow: none !important;
  /* Remove box shadow */
}

.hover-product-content,
.hover-provider-content {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background: transparent;
  backdrop-filter: blur(20px);
  z-index: 10;
  margin-top: 1px;
}

.hoverable-product-link:hover .hover-product-content {
  display: block;
}

.hoverable-provider-link:hover .hover-provider-content {
  display: block;
}

.product-second-list {
  padding-left: 20px;
}

@media (max-width: 768px) {

  .menu-container,
  .buttons {
    display: none;
  }

  .mobile-menu-button {
    display: inline-block;
  }

  .product-second-list {
    padding-left: 0px;
  }
}

@media (max-width: 2560px) {
  .header-container {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }

}
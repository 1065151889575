.ctaSectiond {
    background: #ccccfe;
    padding: 78px 0;
}

.contentAreaCTA {
    text-align: left;
}

.ctaSectiond h2 {
    font-size: 70px;
    font-weight: 700;
    font-family: Poppins;
    line-height: 99px;
    width: 765px;
}

.ctaSectiond h2 span {
    background: #6d33fa;
    padding: 9px 30px 28px 30px;
    border-radius: 80px;
    color: #fff;
}

.btnCTABLOCK button {
    background: #0c043f;
    border: none;
    font-size: 23px;
    padding: 11px 36px;
    color: #fff;
    border-radius: 100px;
    font-family: 'Montserrat', sans-serif;
    line-height: 47px;
    transition: .3s;
}

.btnCTABLOCK {
    margin-top: 50px;
}

.btnCTABLOCK button:hover {
    transition: .3s;
    background: #201754;
    transform: translateX(10px);
}

.ctaSectiond h2 .clr_Two {
    background: #24232b;
    padding-bottom: 15px;
    position: relative;
    left: 20px;
}


@media only screen and (max-width: 550px) {
    .btnArrowtestiminial {
        top: 145px;
    }
    
    .opacityBlockTestiminail p {
        font-size: 13px;
        padding: 0 20px;
        line-height: 17px;
    }
    
    .opacityBlockTestiminail h4 {
        font-size: 16px;
        padding-left: 16px;
    }
    
    .ctaSectiond h2 {
        font-size: 23px;
        width: 100%;
        text-align: center;
        line-height: 42px;
    }
    
    .ctaSectiond h2 .clr_Two {
        left: 0;
    }
    
    .btnCTABLOCK button {
        font-size: 15px;
        padding: 0px 18px;
    }
    
    .ctaSectiond {
        padding: 40px 0;
    }
    
    .btnCTABLOCK {
        margin-top: 21px;
    }
}
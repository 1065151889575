

.banner {
    background-position: 0 60%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #19087e !important;
}
.banner:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000080;
    left: 0;
    top: 0;
    backdrop-filter: blur(8px);
}

.banner h2 {
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    z-index: 1;
}

@media (max-width: 768px) {
    .banner h2 {
        font-size: 40px;
    }
}

@media (max-width: 535px) {
    .banner h2 {
        font-size: 32px;
    }
}
.searchBox{
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;

  
 
}


.reactIcons{
    color: #979393 !important;
    font-size:large;

}
.searchBtn{
   
    /* background-color: var(--bg-primary) !important; */
    color: white !important;
    

}

@media only screen and (min-width: 768px) {
  .searchBox{
    flex-wrap: wrap;
    height: 60vh !important;
  }
  .searchbar_area {
    max-width: 50%;
}
}

/* Styles for devices with a maximum width of 767 pixels (typical for mobile devices) */
@media only screen and (max-width: 767px) {
  /* .searchBox{
    height: 70vh;
    padding-top: 0%;
    padding-bottom: 5em;
  } */
  }
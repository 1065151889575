.pricing-section {
    padding: 50px;
    text-align: center;
}

.pricing-first-section {
    margin-bottom: 100px;
}

.pricing-heading {
    font-weight: 700;
    color: #1E1F4B;
    /* margin-bottom: 26px; */
}

.pricing-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: 2.77px solid #2E3E5C;
    text-align: left;
    max-width: 360px;
    padding: 40px;
    max-height: 380px;
}

.basic-card {
    color: #1E1F4B;
}

.annual-card {
    background-color: #1E1F4B;
    color: #fff;
    border: 2.77px solid #706FE5;
}

.no-border-title .ant-card-head {
    border-bottom: none;

}

.no-border-title .ant-card-head-title {
    color: #1E1F4B;
}

.title-color .ant-card-head-title {
    color: #fff;
}

.no-border-title .ant-card-body {
    padding: 10px 24px;
}

.pricing-card .price {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 66px;
    /* margin: 20px 0; */
}

.pricing-card p {
    /* margin: 10px 0; */
}

.pricing-card button {
    margin-top: 20px;
}

.pricing-tabs {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #2E3E5C;
    max-width: 455px;
    height: 54px;
    align-items: center;
    border-radius: 5.35px;
    margin-left: auto;
    margin-right: auto;
}

.pricing-card-popular {
    position: absolute;
    background: #1E1F4B;
    left: 0;
    right: 0;
    top: -56px;
    width: 130px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    border-radius: 6px 6px 0px 0px;
    font-family: Poppins;
    font-weight: 400;
    font-size: 14.77px;
    font-weight: 400;
    line-height: 22.16px;
    text-align: center;
    border: 2.77px solid #706FE5;
    border-bottom: none;
    letter-spacing: 1px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .pricing-section {
        padding: 0px 30px 30px 30px;
    }

    .pricing-card .price {
        font-size: 1.5em;
    }

    .pricing-first-section {
        margin-bottom: 100px;
    }


}

@media (width: 768px) {
    .pricing-card {
        padding: unset;
    }
}

@media (max-width: 375px) {
    .pricing-card {
        padding: 10px 40px;
        max-height: 290px;
    }

    .pricing-first-section {
        margin-bottom: 100px;
    }

}

@media (max-width: 333px) {
    .pricing-card {
        padding: 10px 0px;
        max-height: 280px;
    }

}

@media (max-width: 340px) {
    .pricing-year-tab {
        padding: 0px 5px;
        margin-top: -8px;
    }

}

@media (max-width: 2560px) {
    .pricing-section {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}
.App {
  text-align: center;
  overflow: hidden;
}

.custom_header button {
  font-family: 'Montserrat', sans-serif;
  color: #19087e !important;
  font-weight: 600 !important;
  transition: .3s;
  padding: 0 20px;
  margin: 0;
  height: 70px;
  border-radius: 0;
}
.membership_box {
  height: auto !important;
  padding: 35px 0 !important;
}

.custom_header button a {
  color: #19087e !important;
}

.custom_header button:hover {
  background: #19087e;
  color: #fff !important;
}

.custom_header button:hover a {
  color: #fff !important;
}

.ourSolution_section {
  background: #f2f2f2;
  padding: 50px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px -8px 20px;
  border-top: 1px solid gainsboro;
}

.ourSolution_section h1 {
  font-size: 50px;
  font-weight: 600;
  color: #19087e;
  padding-bottom: 30px;
}

.ourSolution_section li span {
  color: #272424;
  font-size: 16px;
  font-weight: 500;
}

.ourSolution_section li svg {
  fill: #19087e;
}
.hero_section:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(3deg, rgb(12 4 63 / 87%) 0%, rgb(171 151 151 / 27%) 100%);
}

.explore_section {
  background: #0c043f;
}

.explore_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.explore_div div {
  display: flex;
  align-items: center;
}

.explore_div .img_explore img {
  width: 140px;
}

.explore_div .content_exlpore h2 {
  color: #fff;
  font-size: 42px;
  text-align: left;
  line-height: 37px;
  margin-bottom: 0;
}

.explore_div .content_exlpore {
  padding-left: 30px;
}

.explore_div button {
  background: #fff;
  border-radius: 3px;
  padding: 10px 40px;
  color: #0c043f;
  font-size: 16px;
  font-weight: 500;
}

.about_us {
  padding: 100px 0;
}
.about_us .heading {
  margin-bottom: 80px;
}
.about_us .heading h2{
  font-family: Montserrat, sans-serif !important;
  color: #1c1919;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 28px;
}
.about_us .heading p{
  color: #1c1919;
  max-width: 900px;
  margin: auto;
  line-height: 29px;
  font-size: 18px;
}

.about_us__box {
  background: white;
  padding: 30px;
  border: 1px solid #d5d5d5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: .4s;
  position: relative;
  height: 100%;
}


.about_us__box img {
  margin-bottom: 16px;
}

.about_us__box h4 {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 9px;
}

.about_us__box p {
  color: #000;
  font-size: 16px;
}


.about_us__mission img {
  width: 100%;
}

.about_us__mission .content {
  text-align: left;
  padding-left: 20px;
}

.about_us__mission .content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.about_us__mission .content h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 28px;
}

.content_img {
    height: 523px;
    overflow: hidden;
}

.about_us__mission {
  padding-bottom: 100px;
}

/* Your CSS file (e.g., App.css) */
/* .fade-in {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}
 
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */


@media only screen and (max-width: 1250px) {
  .expertise_section--card{
    transform: rotate(0deg) !important;
    margin-bottom: 30px !important;
  }
  .expertise_section--inner_card {
    transform: rotate(0deg) !important;
}
}

@media only screen and (max-width: 768px) {
  .hide_mobile{
    display: none !important;
  }
  
.explore_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.explore_div div {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.explore_div .content_exlpore {
  padding: 0;
  text-align: center;
}

.explore_div .content_exlpore h2 {
  font-size: 27px;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 26px;
}

.explore_div button {
  margin-bottom: 42px;
}

}

@media only screen and (max-width: 535px) {
  .search__bar h1 {
    font-size: 30px !important;
}

.search__bar .MuiContainer-root {
    width: 90%;
}

h1.logo {
    font-size: 30px;
    margin-bottom: 0;
}

.custom_header button:hover {
    background: transparent !important;
}

.custom_header button svg {
    color: #19087e;
}

#menu-appbar li p a {
    color: #19087e !important;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}


.expertise_section .heading h2 {
    font-size: 34px !important;
}

.expertise_section .heading {
    padding-bottom: 30px;
}

.explore_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.explore_div div {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.explore_div .content_exlpore {
    padding: 0;
    text-align: center;
}

.explore_div .content_exlpore h2 {
    font-size: 27px;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 26px;
}

.explore_div button {
    margin-bottom: 42px;
}

.footer_custom .padding-left {
    padding: 0;
    padding-top: 27px;
}
.ourSolution_section h1 {
  font-size: 34px;
}

.ourSolution_section .MuiGrid-container {
  width: 100%;
}
.service_section h1 {
  font-size: 34px !important;
}
.column.links.padding-left {
  padding: 0;
  padding-top: 23px;
}
}

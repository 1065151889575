/* .hero-section {
    margin-top: 3px;
    padding: 50px;
    background-color: #fff;
} */
.business-container {
    /* margin-top: 50px; */
    padding: 50px
}

.business-content {
    text-align: left;
    position: relative;
    /* margin-left: 30px; */
}

.custom-card {
    background-color: #ffffff;
    border-radius: 13.71px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    height: 95%;
    cursor: pointer;
}

.custom-card h3 {
    color: #1E1F4B;
    padding: 14px 18px 14px 18px;
}


.custom-card p {
    color: #2E3E5C
}

.active-card {
    background-color: #1E1F4B;
    color: #fff;
}

.active-card h3 {
    background-color: #FFFFFF33;
    font-weight: 600;
    padding: 14px 18px 14px 18px;
    border-radius: 13.71px;
    color: #fff;
}

.active-card p {
    color: #fff;
}

.business-content h1 {
    /* font-size: 2.5em; */
    margin-bottom: 20px;
}

.business-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.hero-image {
    text-align: left;
    padding: 0px 35px;
}

.hero-image img {
    max-width: 100%;
    height: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .business-container {
        margin-top: unset;
    }

    .business-content h1 {
        font-size: 2em;
    }

    .business-content p {
        font-size: 1em;
    }

    .hero-section {
        padding: 30px;
    }

    .custom-card {
        margin-bottom: 16px;
    }

    .business-container {
        padding: 30px
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .business-section .ant-col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 375px) {
    .hero-image {
        padding: 0;
    }
}

@media (max-width: 2560px) {
    .business-container {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}
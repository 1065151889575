.fullscreen-modal .ant-modal-content {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    border-radius: 10;
    padding: unset;
    max-width: 1320px;
}

.fullscreen-modal .ant-modal-body {
    padding: 0;
    max-height: 450px;
}

.fullscreen-modal .ant-modal-header,
.fullscreen-modal .ant-modal-footer {
    display: none;
}

.demo-form {
    width: 100%
}

.demo-form input {
    height: 50px;
}

.modal-image {
    display: flex;
    flex: 1;
}

@media (max-width: 768px) {
    .fullscreen-modal .ant-modal-body {
        padding: 10px;
    }

    .demo-form input {
        height: 100%;
    }
}

@media (max-width: 425px) {
    .modal-image {
        display: none;
    }
}

@media (max-width: 320px) {
    .modal-image {
        display: none;
    }

    .ant-form-item {
        margin-bottom: 15px;
    }
}

@media (max-width: 2560px) {
    .fullscreen-modal {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Playfair:opsz,wght@5..1200,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Playfair:opsz,wght@5..1200,300;5..1200,400;5..1200,500;5..1200,600&display=swap');

:root{
  --bg-body: #F3F0FA;
  /* --bg-primary: #5b78bf; */
  /* --bg-primary: #075CFB; */
  --bg-primary: #19087e;
  /* --bg-secondary:#78A1DF ; */
  --bg-secondary: #f2f2f2;
  /* --color-primary: #91b7df; */
  --color-primary:#19087e;
  /* --color-secondary: #b5cde4; */
  --color-secondary : #F2F2F2;
  /* --color-text-primary:#979393; */
  --color-text-primary:#031CA6;
  --color-text-secondary: #ffffff (white);
 
 }


body {
  margin: 0;
  font-family: 'Playfair', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

h1{
  text-transform:'uppercase'
}

h1, h2 {
  font-family: 'Montserrat', sans-serif;
}



/* src/components/NotFound.css */
.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh; /* Full viewport height */
    text-align: center;
}

.not-found-title {
    font-size: 72px;
    margin-bottom: 20px;
}

.not-found-message {
    font-size: 24px;
}

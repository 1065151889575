.forms-Container{
    width: 100%;
    height: 100%;
    background-image: url('../assets/career.jpg ');
    background-size: cover;
    margin:0;
    padding:5%;
    position: relative;
}
.forms-Container:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(3deg, rgb(12 4 63 / 87%) 0%, rgb(171 151 151 / 27%) 100%);
}

.forms-Container .MuiContainer-root {
    position: relative;
    /* backdrop-filter: blur(5px);
    background: #ffffffc9; */
    background: #ffffffeb;
}

.forms-Container .inner_box {
    padding: 25px;
    text-align: left;
}
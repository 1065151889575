
.mainContainer{
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-left: 20% !important;
    margin: 2rem 3rem;
    border-radius: 10px;
    box-shadow: 0px 3px 20px lightgray;
}

.divLeft{
    width: 30%;
    height: 80vh;
    align-items: center !important;
    justify-content: center !important;
    opacity: 90%;
    text-align: center;
    color: white;
    border-radius: 10px 0px 0px 10px;
    visibility: visible;

}

.divRight{
    width: 70%;
    height: auto;
    color:var(--color-text);
    padding-bottom: 3%;
}

.btnGroup{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    
  

    
}

.boxContainer{
   
    width: 25vw;
    height: auto;
    padding: 2rem;
    margin: 5% 35% !important;
    box-shadow:  3px 3px 10px lightgray;
    border: 1px solid silver;
    border-radius: 10px !important;
    color:var(--color-text-primary);


}
.profileContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50vw;
    height: 90vh;
    margin-left: 25% !important;
    margin: 2rem ;
    border-radius: 10px;
    box-shadow: 0px 3px 20px lightgray;
    color: var(--color-text-primary);

}

.profileHeader{
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    margin: 2px;
    width: 90%;
    padding: 2px;
   

}

.profileBody{
    width: 90%;
    align-items: flex-start !important;
    justify-content: center !important;


}

@media (max-width: 880px) {
   
    .mainContainer{
        height: auto;
    }
	.divLeft{
        width: 0;
        visibility: hidden;
    }
    .divRight{
        width: 100vw;
    }
    h1{
        font-size: smaller;
    }
    .boxContainer{
        width: 50vw !important;
        margin: 5% 15% !important;
    }

}
.tabsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 60px;
}

.tabsSection .contentTabsBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tabsSection .imgBlockTabs {
    width: 100%;
    text-align: left;
    margin-top: 40px;
}

.tabsSection .contentTabsBlock h2 {
    text-align: left;
    color: #19087e;
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 40px;
    font-family: Poppins;
    line-height: 48px;
}

.tabsBtn {
    background: #ffffff;
    padding: 30px 15px 20px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 0 8px;
    width: 300px;
    flex-direction: column;
}

.tabsBtn .iconBlock {
    background: #f4f1f1;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #706fe5;
    transition: .3s;
    margin-bottom: 15px;
}

.tabsBtn .contentBlockTabs {
    text-align: center;
    color: #19087e;
    font-family: 'Montserrat', sans-serif;
}

.tabsBtn:hover {
    background: #706fe5;
    transition: .3s;
}

.tabsBtn:hover .contentBlockTabs{
    color: #fff;
}

.tabsBtn:hover .contentBlockTabs p{
    color: #fff;
}



.tabsBtn .contentBlockTabs h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 13px;
}

.tabsBtn .contentBlockTabs p {
    font-size: 14px;
    color: #6c5dc5;
}

.tabsSection .imgBlockTabs img {
    width: 100%;
    border-radius: 10px;
}

.tabsSection .contentTabsBlock h2 span {
    color: #706fe5;
}

.tabsBtn.activeTab.bg-white.shadow-md {
    background: #706fe5 !important;
}
.tabsBtn.activeTab.bg-white.shadow-md .contentBlockTabs {
    color: #fff;
}
.tabsBtn.activeTab.bg-white.shadow-md .contentBlockTabs p{
    color: #fff;
}


@media only screen and (max-width: 550px) {
    .tabsSection {
        flex-direction: column;
    }
    .tabsBtn .iconBlock {
        width: 70px;
        margin-right: 15px;
    }
    .tabsBtn .contentBlockTabs {
        text-align: left;
    }
    .tabsBtn {
        width: 100%;
        margin: 0 0 16px 0;
        flex-direction: row;
    }
    .tabsSection .contentTabsBlock {
        width: 100%;
        flex-direction: column;
    }
    
    .tabsSection .imgBlockTabs {
        width: 100%;
        text-align: center;
    }
  }
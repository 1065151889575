.product-section {
    padding: 10px 50px;
}

.product-content {
    text-align: left;
    position: relative;
    /* margin-left: 30px; */
    line-height: 4rem;
}

.product-content ul {
    list-style-type: none;
    padding-left: 0;
}

.product-content ul li {
    color: #2E3E5C;
    font-size: 16px;
    font-family: Montserrat;
}

.product-content h1 {
    /* font-size: 2.5em; */
    margin-bottom: 20px;
}

.product-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.product-image {
    text-align: left;
    padding: 0px 35px;
}

.product-image img {
    max-width: 100%;
    height: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .product-content h1 {
        font-size: 2em;
    }

    .product-content p {
        font-size: 1em;
    }

    .product-section {
        padding: 30px;
    }

    .product-image {
        padding: unset;
    }

    .product-section .ant-col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 2560px) {
    .product-section {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}
.pringPage .heading {font-weight: 700;color: #1E1F4B;font-size: 45px;}

.pringPage {
    padding: 80px 0 0;
}

.prickBlockDiv {
    background: #fff;
    width: 435px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    border: 1px solid #1e1f4b;
    overflow: hidden;
    position: relative;
    margin-top: 30px;
}

.pricingCardDivBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all ease .5s;
}
.btmSection {
    background: #fff;
    height: 150px;
    z-index: 3;
    position: relative;
}
.prickBlockDiv .priceHeading {
    font-size: 60px;
    font-weight: 700;
    color: #fff;
}

.prickBlockDiv .priceHeading span {
    font-size: 16px;
    font-weight: 400;
}

.oldPriceData {
    text-decoration: line-through;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: #b9b4b4;
    margin-top: -4px;
    display: block;
    margin-bottom: 8px;
}

.priceBlockHeadingTop {
    text-align: left;
    background: #1E1F4B;
    padding: 50px 30px 25px 30px;
}

p.priceSubHeading {
    text-align: left;
    font-size: 20px;
    margin-bottom: 0;
    color: #fff;
    line-height: 25px;
    font-style: italic;
}

.priceList {
    padding: 30px 35px 15px;
}

.priceList li {
    color: #25252e;
    list-style: none;
    text-align: left;
    font-size: 18px;
    line-height: 30px;
}

.priceList li svg {
    color: #1e1f4b;
}

.priceBlockBTN {
    padding: 0 20px 35px 20px;
}

.btnPrice {
    color: #fff;
    width: 100%;
    height: 55px;
    font-size: 29px;
    border-radius: 100px;
    border: none;
    background: #706FE5;
    text-transform: uppercase;
    transition: .3s;
}

.btnPrice:hover {
    transition: .3s;
    transform: translateY(10px);
    background: #1E1F4B;
}

.btnPriceTbas {
    background: #706FE5;
    border-radius: 10px;
    overflow: hidden;
}

.btnPriceTbas button {
    background: transparent;
    border: transparent;
    color: #fff;
    font-size: 18px;
    border-radius: 0 !important;
    transition: .3s;
}

.btnPriceTbas button:hover {
    background: #1e1f4b;
    transition: .3s;
}

.btnPriceTbas button.active {
    background: #1e1f4b;
}

.bubble-badge {
            background: #706fe5;
            color: white;
            font-size: 18px;
            font-weight: bold;
            padding: 8px 22px;
            border-radius: 50px;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
            animation: bounce 1.2s infinite;
            position: relative;
            top: 55px;
            z-index: 1;
        }

        @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-5px); }
        }

        h3.headingSubP {
            margin-bottom: 70px !important;
            margin-top: 19px !important;
            width: 535px;
            margin: auto;
            line-height: 29px;
            color: #0b0b0b;
            font-size: 22px;
        }
        
        .plansBlock {
            display: flex;
            justify-content: space-around;
            padding-bottom: 50px;
        }
        
        .pricingPointsCardBlock {
            text-align: left;
            margin-bottom: 35px;
        }
        
        .pricingPointsCardBlock h3 {
            font-size: 26px;
            margin-bottom: 8px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            color: #1e1f4b;
        }
        
        .pricingPointsCardBlock ul li {list-style: none;border-bottom: 1px solid #605d5d;line-height: 55px;font-size: 19px;}
        
        .pricingPointsCardBlock ul {
            padding-left: 0;
        }
        
        .pricingPointsCardBlock ul li:last-child {
            border: none;
        }


        .isFixedRightCol {
            position: fixed;
            top: 32px;
            transform: translateX(270px);
            transition: all ease .5s;
        }
        
        .isFixedLeftCol {
            transform: translateX(-270px);
            transition: all ease .5s;
        }
        .lanDetailsP {
            transition: all ease .5s;
        }

        @media only screen and (max-width: 550px) {

            .plansBlock {
                display: flex;
                flex-direction: column-reverse;
            }
            
            .pricingCardDivBlock {padding: 0 15px;margin-bottom: 50px;}
            
            .prickBlockDiv {
                width: 100%;
            }
            
            .bubble-badge {
                font-size: 15px;
            }
            
            .btnPrice {
                font-size: 20px;
            }
            
            .priceList li {
                font-size: 14;
                font-size: 15px;
            }
            
            .priceList li svg {
                width: 18px;
            }
            
            .lanDetailsP {
                padding: 0 20px;
            }
            
            .pricingPointsCardBlock ul li {
                line-height: 35px;
                font-size: 16px;
            }
            
            .pricingPointsCardBlock h3 {
                font-size: 19px;
            }
            
            h3.headingSubP {
                width: 100%;
                font-size: 17px;
                line-height: 22px;
                padding: 0 20px;
            }
            
            .pringPage .heading {
                padding: 0 20px;
                font-size: 32px;
            }
        }
.hero-section {
    margin-top: 3px;
    padding: 50px;
    background-color: #fff;
}

.hero-content {
    text-align: left;
    position: relative;
    margin-left: 30px;
}

.hero-gif {
    display: flex;
    border: 1px solid #F3F5FA;
    background: #FFFFFF;
    border-radius: 12px;
    max-width: 356px;
    justify-content: space-around;
    align-items: center;
    height: 53px;
    box-shadow: 0px 12px 24px -4px #0000001F;
    margin-bottom: 30px;
}

.hero-report-gif {
    background: #F1F1F1;
    border-radius: 8px;
    max-width: 104px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 10px;
}

.hero-report-gif span {
    color: #212B36;
    font-family: Public Sans;
    font-size: 14px;
    font-weight: 400;
}

.hero-content-arrow {
    position: absolute;
    right: 0;
    top: 37%;
}

.hero-content h1 {
    /* font-size: 2.5em; */
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.hero-image {
    text-align: center;
}

.hero-image img {
    max-width: 100%;
    height: auto;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .hero-content-arrow {
        top: 15%;
    }
}

@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 2em;
    }

    .hero-content p {
        font-size: 1em;
    }

    .hero-section {
        padding: 30px;
    }

    .hero-content-arrow {
        top: 5%;
        right: -5%;
    }

    .hero-content-arrow img {
        height: 100px;
    }
}

@media (max-width: 375px) {
    .hero-content-arrow {
        top: 25%;
    }
}

@media (max-width: 320px) {
    .hero-report-gif {
        padding: 0px 10px;
    }

    .hero-content-arrow {
        top: 35%;
    }

    .hero-content-arrow img {
        height: 70px;
    }
}

@media (max-width: 2560px) {
    .hero-section {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}